<template>
    <step-settings-wrapper title="Camera Settings" v-on:close="onClose" v-on:save="onSave">
        <div class="mt-1 max-w-lg mx-auto">

            <div class="pb-5 pt-5 border-b border-gray-custom-3 clearfix">
                <div class="float-left text-3xl uppercase font-bold tracking-wider">
                    Camera
                </div>
                <div class="float-right pt-1">

                    <div class="flex -mx-5">

                        <div class="w-1/2 px-5 cursor-pointer mr-1 camera-direction front" :class="{'active': preset.cameraSettings.rawCameraDirection === 0}" @click="preset.cameraSettings.rawCameraDirection = 0">
                            <div class="h-10 w-5 relative left-50 -translate-x-25 icons">
                                <div class="absolute text-xl icon-left"><font-awesome-icon :icon="leftArrowIcon"></font-awesome-icon></div>
                                <div class="absolute text-xl icon-right"><font-awesome-icon :icon="rightArrowIcon"></font-awesome-icon></div>
                            </div>
                            <div class="uppercase text-xl font-bold tracking-wider pt-3">Front</div>
                        </div>

                        <div class="w-1/2 px-5 cursor-pointer camera-direction back" :class="{'active': preset.cameraSettings.rawCameraDirection === 1}" @click="preset.cameraSettings.rawCameraDirection = 1">
                            <div class="h-10 w-5 relative left-50 -translate-x-25 icons">
                                <div class="absolute text-xl icon-left"><font-awesome-icon :icon="leftArrowIcon"></font-awesome-icon></div>
                                <div class="absolute text-xl icon-right"><font-awesome-icon :icon="rightArrowIcon"></font-awesome-icon></div>
                            </div>
                            <div class="uppercase text-xl font-bold tracking-wider pt-3">Back</div>
                        </div>

                    </div>

                </div>
            </div>

            <div class="pb-3 pt-3 border-b border-gray-custom-3 clearfix">
                <div class="float-left text-3xl uppercase font-bold tracking-wider">
                    Countdown
                </div>
                <div class="float-right pt-1">
                    <div class="inline align-bottom font-bold text-xl pr-6 leading-loose">{{preset.cameraSettings.countDown}}</div>
                    <number-input controls size="small" inline :min="0" v-model="preset.cameraSettings.countDown" class="vue-number-input"></number-input>
                </div>
            </div>

            <div class="pb-3 pt-3 border-b border-gray-custom-3 clearfix">
                <div class="float-left text-3xl uppercase font-bold tracking-wider">
                    Capture Delay
                </div>
                <div class="float-right pt-1">
                    <div class="inline align-bottom font-bold text-xl pr-6 leading-loose">{{preset.cameraSettings.startDelay}}</div>
                    <number-input controls size="small" inline :min="0" v-model="preset.cameraSettings.startDelay" class="vue-number-input"></number-input>
                </div>
            </div>

            <div class="pb-3 pt-3 border-b border-gray-custom-3 clearfix" v-if="preset.photoDesignSettings.rawPhotoshootType !== 5">
                <div class="float-left text-3xl uppercase font-bold tracking-wider">
                    Quality
                </div>
                <div class="float-right pt-1">

                    <div class="text-black uppercase font-bold tracking-wider text-xl">
                        <input type="radio" name="quality" id="quality_low" :value="0" v-model="preset.cameraSettings.rawCaptureQuality" class="absolute opacity-0"><label for="quality_low" class="rounded-l" :class="[quality.defaultClasses, preset.cameraSettings.rawCaptureQuality === 0 ? quality.activeClasses : '']">Low</label>
                        <input type="radio" name="quality" id="quality_medium" :value="1" v-model="preset.cameraSettings.rawCaptureQuality" class="absolute opacity-0"><label for="quality_medium" :class="[quality.defaultClasses, preset.cameraSettings.rawCaptureQuality === 1 ? quality.activeClasses : '']">Medium</label>
                        <input type="radio" name="quality" id="quality_high" :value="2" v-model="preset.cameraSettings.rawCaptureQuality" class="absolute opacity-0"><label for="quality_high" class="rounded-r" :class="[quality.defaultClasses, preset.cameraSettings.rawCaptureQuality === 2 ? quality.activeClasses : '']">High</label>
                    </div>

                </div>
            </div>

            <div class="pb-3 pt-3 border-b border-gray-custom-3 clearfix" v-if="preset.photoDesignSettings.rawPhotoshootType === 5">
                <div class="float-left text-3xl uppercase font-bold tracking-wider">
                    Video Length
                </div>
                <div class="float-right pt-1">
                    <div class="inline align-bottom font-bold text-xl pr-6 leading-loose">{{preset.cameraSettings.recordingLength}}</div>
                    <number-input controls size="small" inline :min="1" v-model="preset.cameraSettings.recordingLength" class="vue-number-input"></number-input>
                </div>
            </div>

            <div class="pb-3 pt-3 border-b border-gray-custom-3 clearfix" v-if="preset.photoDesignSettings.rawPhotoshootType === 5">
                <div class="float-left text-3xl uppercase font-bold tracking-wider">
                    Reverse Video
                </div>
                <div class="float-right pt-1">
                    <toggle-button :labels="toggleButtonSettings.labels" :color="toggleButtonSettings.color" :switch-color="toggleButtonSettings.switchColor" :width="toggleButtonSettings.width" :height="toggleButtonSettings.height" :margin="toggleButtonSettings.margin" name="reversed" :value="reversed" @change="updateToggleValue"></toggle-button>
                </div>
            </div>

            <div class="pb-3 pt-3 border-b border-gray-custom-3 clearfix" v-if="preset.photoDesignSettings.rawPhotoshootType === 5">
                <div class="float-left text-3xl uppercase font-bold tracking-wider">
                    Loop Video
                </div>
                <div class="float-right pt-1">
                    <toggle-button :labels="toggleButtonSettings.labels" :color="toggleButtonSettings.color" :switch-color="toggleButtonSettings.switchColor" :width="toggleButtonSettings.width" :height="toggleButtonSettings.height" :margin="toggleButtonSettings.margin" name="looped" :value="looped" @change="updateToggleValue"></toggle-button>
                </div>
            </div>

            <div class="mt-10 ml-3 square-box relative overflow-y-hidden text-6xl text-center uppercase tracking-medium leading-tight w-4/6 text-turquoise text-shadow">
                <span class="absolute top-50 left-50 -translate-50">{{preset.cameraSettings.countDown}}</span>
            </div>
            <div class="h-20"></div>

        </div>
    </step-settings-wrapper>
</template>

<script>
    // @ is an alias to /src
    import StepSettingsWrapper from '@/components/StepSettingsWrapper.vue';
    import { faLongArrowAltRight, faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
    import NumberInput from '@chenfengyuan/vue-number-input';
    import { ToggleButton } from 'vue-js-toggle-button';

    export default {
        name: 'CameraSettings',
        data: function() {
            return {
                quality: {
                    defaultClasses: 'inline-block px-4 pt-1 border-r border-gray-custom cursor-pointer bg-white',
                    activeClasses: 'bg-turquoise'
                },
                reversed: this.preset.cameraSettings.reversed === 'true' || this.preset.cameraSettings.reversed === true,
                looped: this.preset.cameraSettings.looped === 'true' || this.preset.cameraSettings.looped === true,
                beforeEditing: {}
            }
        },
        props: {
            preset: Object,
            toggleButtonSettings: Object
        },
        components: {
            StepSettingsWrapper,
            ToggleButton,
            NumberInput
        },
        mounted: function() {
            this.beforeEditing = Object.assign({}, this.preset.cameraSettings);
        },
        methods: {
            updateToggleValue: function(element) {
                if( element.value === true ) {
                    this.preset.cameraSettings[element.srcEvent.target.name] = 'true';
                } else {
                    this.preset.cameraSettings[element.srcEvent.target.name] = 'false';
                }
            },
            onClose: function(toRoute) {
                //this.preset.cameraSettings = Object.assign({}, this.beforeEditing);
                let filesSelected = 0;
                this.$emit('close', 'cameraSettings', this.beforeEditing, filesSelected, toRoute);
            },
            onSave: function() {
                this.$emit('save', 'cameraSettings');
            }
        },
        computed: {
            rightArrowIcon: function() {
                return faLongArrowAltRight
            },
            leftArrowIcon: function() {
                return faLongArrowAltLeft;
            }
        }
    }
</script>

<style scoped>
    .camera-direction.active {
        color: #0DE9FF;
    }
    .camera-direction .icons .icon-right {
        top: 17px;
    }
    .camera-direction .icons .icon-left:after {
        content: "";
        display: block;
        background: white;
        width: 2px;
        height: 11px;
        position: absolute;
        right: 0;
        bottom: 6px;
    }
    .camera-direction .icons .icon-right:before {
        content: "";
        display: block;
        background: white;
        width: 2px;
        height: 11px;
        position: absolute;
        left: 0;
        top: 4px;
    }
    .camera-direction.active .icons .icon-left:after, .camera-direction.active .icons .icon-right:before {
        background: #0DE9FF;
    }
    .camera-direction .icons .icon-right svg {
        position: relative;
        left: 1px;
    }
    .camera-direction.back .icons {
        transform: translateX(-70%) rotate3d(0, 1, 0, -180deg);
    }
    .square-box {
        background:url(../assets/images/image_overlay_background.png) no-repeat;
        background-size: cover;
    }
    .square-box:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }
    .text-shadow {
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.75);
    }
    @media (max-width: 400px) {
        .float-right {
            float: left;
            clear:both;
        }
    }
</style>

<style>
    .vue-number-input {
        /*min-height: 2.1rem;*/
    }
    .vue-number-input input {
        visibility: hidden;
        width: 5rem !important;
    }
    .vue-number-input button:first-child, .vue-number-input button:last-child {
        border-radius: 50%;
        height: 30px;
        width: 30px !important;
    }
    .vue-number-input button:last-child:before, .vue-number-input button:last-child:after, .vue-number-input button:last-child:hover:before, .vue-number-input button:last-child:hover:after {
        background-color: #0DE9FF;
    }
    .vue-number-input button:first-child:before, .vue-number-input button:first-child:not(.disabled):hover:before {
        background-color: #0DE9FF;
    }
    .vue-number-input button:last-child:before, .vue-number-input button:first-child:before {
        height: 3px;
    }
    .vue-number-input button:last-child:after {
        width: 3px;
    }
    /*.vue-number-input button:first-child:before {*/
    /*    box-shadow: 0 1px 1px #cfcfcf;*/
    /*}*/
</style>